header {
    position:fixed;
    top:0;
    right:0;
    left:0;
    margin:auto;
    width:100%;
    z-index:11;
    background:$navy;
    color:#fff;

    @include media-max(960px) {
        font-size:rem(14px);
    }

    .logo {
        width:rem(120px);
    }

    .row {
        display:flex;
        align-items: center;
        padding:rem(20px 25px);

        ul {
            list-style:none;
            padding:0;
            margin:0;
            display:flex;
            justify-items: flex-end;
            justify-content: flex-end;

            @include media-max(640px) {
                align-items: center;
                align-content: center;
                justify-items: center;
                justify-content: center;
                flex-direction: column;
                gap:rem(20px);
            }

            li {
                a {
                    padding:rem(10px 20px);
                    
                    @include media-max(960px) {
                        padding:rem(10px 10px);
                    }
                    @include media-max(640px) {
                        padding:rem(25px);
                        font-size:rem(22px);
                    }
                }
            }
        }

        nav {
            margin-left:auto;

            @include media-max(640px) {
                position:fixed;
                top:0;
                right:0;
                bottom:0;
                left:0;
                margin:auto;
                width:100vw;
                height:100vh;
                z-index:999999;
                background:$navy;
                display:flex;
                align-items: center;
                align-content: center;
                justify-items: center;
                justify-content: center;
            }
        }
        .menu__toggle {
            margin-left:auto;
        }
    }
}
.menu__close {
    position:fixed;
    top:rem(20px);
    right:rem(20px);
}
