.grid {
    display:grid;
}
.grid__1_1 {
    grid-template-columns:1fr 1fr;

    @include media-max(640px) {
        grid-template-columns: 1fr;
    }
}
.grid__1_1_1 {
    grid-template-columns:1fr 1fr 1fr;
    
    @include media-max(960px) {
        grid-template-columns:1fr 1fr;
    }
    @include media-max(640px) {
        grid-template-columns: 1fr;
    }
}
.grid__1_1_1_1 {
    grid-template-columns:1fr 1fr 1fr 1fr;
    
    @include media-max(960px) {
        grid-template-columns:1fr 1fr;
    }
    @include media-max(640px) {
        grid-template-columns: 1fr;
    }
}
.push__pull {
    @include media-max(960px) {
        display:none;
    }
}