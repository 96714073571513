section {
    padding-top:rem(110px);
    padding-bottom:rem(110px);
    font-size:rem(18px);
}
.sep {
    margin-top:rem(30px);
    margin-right:auto;
    margin-bottom:rem(30px);
    margin-left:auto;
    display:block;
}
h2 {
    font-size:rem(30px);
    font-weight:700;
}
.title__group {
    text-align:center;
    width:rem(860px);
    margin-right:auto;
    margin-left:auto;

    @include media-max(960px) {
        width:100%;
    }
}
.hero__section {
    color:#fff;
    height:100vh;
    background:$navy;
    padding:0;
    position:relative;
    z-index:13;

    .logo__animation {
        position:relative;
        width:rem(700px);
        height:rem(300px);
        margin-bottom:rem(130px);

        .logo__anima {
            position:absolute;
            left:50%;
            top:50%;
            transform: translate(-50%, -50%);
        }
    }

    .title__section {
        position: absolute;
        top:0;
        left:0;
        width:100vw;
        height:100vh;
        display:flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
        z-index:99;
        text-align: center;

        .logo__anim {
            margin-bottom:rem(130px);
            width:rem(700px);

            @include media-max(960px) {
                max-width:60%;
            }
            @include media-max(640px) {
                max-width:70%;
            }
            @include media-max(460px) {
                max-width:80%;
            }
        }
        
        h1 {
            font-size:rem(30px);
            font-weight:700;
        }
    }
    .bg__vid {
        height:100vh;
    }
    .hero__bar {
        background:$navylight;
        width:100%;
        position: absolute;
        right:0;
        left:0;
        bottom:0;
        z-index:101;
        text-align:center;
        padding:rem(20px);

        .img, svg {
            cursor: pointer;
        }
    }
}
.about_section {
    background-position: center;
    background-repeat: no-repeat;
    
    @include media-max(960px) {
        background-size: cover;
    }

    h2 {
        line-height:1.5;
    }

    .grid {
        align-content: end;
        align-items: end;
        grid-gap:rem(65px);
    }
    .sep {
        margin-left:0;
        margin-right:auto;
    }
    .logo__anim {
        position: absolute;
        z-index:9;
    }
}
.numbers__section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color:#fff;

    .grid {
        grid-gap:rem(45px);
    }

    h2 {
        margin:0;
    }
    .sep {
        margin-bottom:0;
    }

    .number__card {
        border:2px solid #fff;
        border-radius:9px;
        display:flex;
        align-items: center;
        align-content: center;
        
        .card__inner {
            min-height:rem(250px);
            padding:rem(30px 60px 30px 30px);
            width:100%;
            display:grid;
            align-items: center;
            align-content: center;
        }
        .card__title {
            font-size:rem(48px);
            font-weight:700;
        }

        &.featured__card {
            background:#fff;
            color:$navy;

            @include media-max(960px) {
                order:-1;
            }
        }
    }

}
.services__section {

    @include media-max(1200px) {
        padding-bottom:0;
    }

    .grid {
        grid-gap:rem(45px);
    }
    .services__cards {
        margin-top:rem(80px);
    }
    .service__card {
        height:rem(480px);
        border-radius:9px;
        overflow: hidden;
        box-shadow:0 0 15px rgba(0,0,0,0.35);
        position:relative;

        @include media-max(640px) {
            height:rem(340px);
        }
        
        & * {
            pointer-events: none;
        }

        .card__inner {
            position: absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            margin:auto;
            width:100%;
            height:100%;
            z-index:9;
            transition: $t-all;
            color:#fff;

            .card__icon, img, svg {
                position:absolute;
                top:rem(140px);
                left:50%;
                transform:translateX(-50%);
                width:rem(150px);
                transition: $t-all;

                @include media-max(1200px) {
                    width:rem(120px);
                    top:rem(140px);
                }
                @include media-max(640px) {
                    top:rem(100px);
                }
            }
            .card__title {
                position:absolute;
                top:rem(290px);
                left:50%;
                transform:translateX(-50%);
                margin-right:rem(30px);
                font-size:rem(19px);
                letter-spacing: -1px;
                font-weight:700;
                word-break: keep-all;
                transition: $t-all;
                text-align:center;

                @include media-max(1200px) {
                    top:rem(280px);
                    font-size:rem(15px);
                }
                @include media-max(640px) {
                    top:rem(230px);
                }
            }
            .card__desc {
                position:absolute;
                top:rem(145px);
                left:rem(30px);
                margin-right:rem(30px);
                font-size:rem(15px);
                opacity:0;
                transition: $t-all;

                @include media-max(1200px) {
                    font-size:rem(13px);
                }
            }
        }
        &.blue {
            .card__inner {
                background:$blue;
            }
        }
        &.red {
            .card__inner {
                background:$red;
            }
        }
        &.yellow {
            .card__inner {
                background:$yellow;
            }
        }
        &:hover {
            .card__inner {
                background:#fff;
            }
           
            .card__bg {
                opacity:0;
            }
            
            .card__icon, img, svg {
                position:absolute;
                top:rem(30px);
                left:rem(30px);
                width:rem(43px);
                transform:translateX(0%);
                color:$text;
            }
            .card__title {
                position:absolute;
                top:rem(100px);
                left:rem(30px);
                transform:translateX(0%);
                margin-right:rem(30px);
                color:$text;
                // text-align: left;
            }
            .card__desc {
                position:absolute;
                top:rem(145px);
                left:rem(30px);
                margin-right:rem(30px);
                opacity:1;
                color:$text;
            }
        }
    }
}
.team__section {
    margin-bottom:0;
    padding-bottom:0;

    img {
        width:100%;
    }

    .team__cards {

        .persona__card {
            position:relative;

            .card__desc {
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                left:0;
                margin:auto;
                width:100%;
                height:100%;
                background:$blue2;
                color:#fff;
                display:grid;
                grid-template-columns:auto rem(60px);
                align-content: end;
                align-items: end;
                justify-content: space-between;
                justify-items: space-between;
                padding:rem(50px 60px);
                opacity:0;
                transition:$t-all;

                @include media-max(1200px) {
                    padding:rem(25px 35px);
                }
                
                .card__title {
                    font-size:rem(24px);
                    font-weight:700;
                    margin-bottom:rem(15px);

                    @include media-max(1200px) {
                        font-size:rem(20px);
                    }
                }
                .card__position {
                    font-size:rem(15px);
                }
                .persona__sm {
                    text-align:right;
                }
            }

            &:hover {
                .card__desc {
                    opacity:1;
                }
            }
        }
    }
}