footer {
    background:$navy;
    color:#fff;
    padding-top:rem(80px);

    .footer__row {
        display:flex;
        justify-content: center;
        justify-items: center;
        flex-wrap:wrap;

        & > div {
            text-align:center;
            padding:rem(25px 35px);

            &:not(:last-of-type) {
                border-right:1px solid rgba(255,255,255,0.3);
            }

            @include media-max(1200px) {
                flex:1 0 33%;
            }
            @include media-max(960px) {
                &:nth-of-type(3) {
                    border-right:0;
                }
            }
            @include media-max(640px) {
                flex:1 0 100%;
                border-right:0 !important;

                &:not(:last-of-type) {
                    border-bottom:1px solid rgba(255,255,255,0.3);
                }
            }

            img, svg {
                margin-bottom:rem(25px);
            }

            strong {
                display:block;
            }
            
        }
        .cta {
            text-align:left;

            @include media-max(1200px) {
                flex:1 0 100%;
                text-align:center;
                border-right:0 !important; 
            }
        }
        .address {
            font-size:rem(14px);
        }
    }
    .copyright {
        background:$navylight;
        color:#9C9CBB;
        text-align:center;
        margin-top:rem(60px);
        padding:rem(20px);

        p {
            margin:0;
        }
        small {
            display:block;
            font-size:rem(12.5px);
        }
    }
}
