.wrapper {
  max-width:100%;
  width:rem(1170px);
  margin-right:auto;
  margin-left:auto;

  @include media-max(1200px) {
    width:rem(1000px);
  }
  @include media-max(960px) {
    width:90%;
  }
}
